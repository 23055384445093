<template>
    <r-e-dialog title="审核" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer
            top="10vh" width="1315px" @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel"
    >
        <el-form ref="formPublish" label-width="90px" size="small" :model="applyData" :rules="rules">
            <div class="flex">
                <el-form-item label="申请人" prop="">
                    <div style="width: 225px;">
                        {{applyData.applicantName}}
                    </div>
                </el-form-item>
                <el-form-item label="联系方式" prop="">
                    <div style="width: 200px;">
                        {{applyData.applicantPhone}}
                    </div>
                </el-form-item>
                <el-form-item v-if="applyData.sublease===1" label="实际承租人姓名" prop="" label-width="120px">
                    <div style="width: 180px;">
                        {{applyData.actualName}}
                    </div>
                </el-form-item>
                <el-form-item v-if="applyData.sublease===1" label="实际承租人联系方式" prop="" label-width="140px">
                    <div style="width: 180px;">
                        {{applyData.actualPhone}}
                    </div>
                </el-form-item>
            </div>

            <div class="flex">
                <el-form-item label="营业执照" prop="">
                    <div style="background: #f5f7fa;width: 225px;height: 150px;display: flex;justify-content: center;border: 1px solid #F1F1F3;border-radius: 5px">
                        <el-image :src="'https://zshc.neoyon.com/hicity/security/third/wisdom/files?uuid='+applyData.businessLicensePic"
                                  @click="handlePictureCardPreview(applyData.businessLicensePic)"  style="display:flex;align-items: center;">
                            <div slot="error" style="font-size: 30px;">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                            <div slot="placeholder">
                                加载中...
                            </div>
                        </el-image>
                    </div>

                </el-form-item>
                <el-form-item label="承租人身份证正面" prop="">
                    <div style="background: #f5f7fa;width: 225px;height: 150px;display: flex;justify-content: center;border: 1px solid #F1F1F3;border-radius: 5px">
                        <el-image :src="'https://zshc.neoyon.com/hicity/security/third/wisdom/files?uuid='+applyData.idCardFront"
                                  @click="handlePictureCardPreview(applyData.idCardFront)" style="display:flex;align-items: center;">
                            <div slot="error" style="font-size: 30px;">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                            <div slot="placeholder">
                                加载中...
                            </div>
                        </el-image>
                    </div>
                </el-form-item>
                <el-form-item label="承租人身份证反面" prop="">
                    <div style="background: #f5f7fa;width: 225px;height: 150px;display: flex;justify-content: center;border: 1px solid #F1F1F3;border-radius: 5px">
                        <el-image :src="'https://zshc.neoyon.com/hicity/security/third/wisdom/files?uuid='+applyData.idCardReverse"
                                  @click="handlePictureCardPreview(applyData.idCardReverse)" style="display:flex;align-items: center;">
                            <div slot="error" style="font-size: 30px;">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                            <div slot="placeholder">
                                加载中...
                            </div>
                        </el-image>
                    </div>
                </el-form-item>
                <el-form-item label="其他证明" prop="" v-if="applyData.otherCertificate!==''">
                    <div style="background: #f5f7fa;width: 225px;height: 150px;display: flex;justify-content: center;border: 1px solid #F1F1F3;border-radius: 5px">
                        <el-image :src="'https://zshc.neoyon.com/hicity/security/third/wisdom/files?uuid='+applyData.otherCertificate"
                                  @click="handlePictureCardPreview(applyData.otherCertificate)" style="display:flex;align-items: center;">
                            <div slot="error" style="font-size: 30px;">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                            <div slot="placeholder">
                                加载中...
                            </div>
                        </el-image>
                    </div>
                </el-form-item>
            </div>
            <div class="flex" v-if="applyData.sublease===1">
                <el-form-item label="实际身份证正面" prop="">
                    <div style="background: #f5f7fa;width: 225px;height: 150px;display: flex;justify-content: center;border: 1px solid #F1F1F3;border-radius: 5px">
                        <el-image :src="'https://zshc.neoyon.com/hicity/security/third/wisdom/files?uuid='+applyData.actualCardFront"
                                  @click="handlePictureCardPreview(applyData.actualCardFront)" style="display:flex;align-items: center;">
                            <div slot="error" style="font-size: 30px;">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                            <div slot="placeholder">
                                加载中...
                            </div>
                        </el-image>
                    </div>
                </el-form-item>
                <el-form-item label="实际身份证反面" prop="">
                    <div style="background: #f5f7fa;width: 225px;height: 150px;display: flex;justify-content: center;border: 1px solid #F1F1F3;border-radius: 5px">
                        <el-image :src="'https://zshc.neoyon.com/hicity/security/third/wisdom/files?uuid='+applyData.actualCardReverse"
                                  @click="handlePictureCardPreview(applyData.actualCardReverse)" style="display:flex;align-items: center;">
                            <div slot="error" style="font-size: 30px;">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                            <div slot="placeholder">
                                加载中...
                            </div>
                        </el-image>
                    </div>
                </el-form-item>
            </div>
        </el-form>
        <div style="border-top: 1px solid #F1F1F3;" v-if="type==='shen'">
            <el-form ref="formPublish" label-width="90px" size="small" :model="applyData" :rules="rules">
                <div class="flex" style="justify-content: space-between">
                    <el-form-item label="当前状态" prop="">
                        <div style="width: 225px;">
                            {{status}}
                        </div>
                    </el-form-item>
                    <el-form-item label="审核" prop="">
                        <el-select v-model="applyData.statusCurrent" style="width: 225px;" placeholder="请选择">
                            <el-option
                                    v-for="(item,index) in list"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="减租方式" prop="">
                        <el-select v-model="applyData.way" style="width: 225px;" placeholder="请选择">
                            <el-option label="直接减免" value="直接减免"></el-option>
                            <el-option label="下一期租金抵减" value="下一期租金抵减"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="减租金额" prop="">
                        <el-input type="number" v-model="applyData.rentAmount" style="width: 225px;" @input="$forceUpdate()" placeholder="" />
                    </el-form-item>
                </div>
                <div class="flex" style="justify-content: space-between">
                    <el-form-item label="日期" prop="">
                        <el-date-picker
                                v-model="applyData.noticeDate"
                                type="date"
                                placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="批复" prop="">
                        <el-input type="textarea" v-model="applyData.latestReply" :autosize="{ minRows: 3, maxRows: 8}" style="width: 350px;" @input="$forceUpdate()" placeholder="">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="备注" prop="">
                        <el-input type="textarea" v-model="applyData.comment" :autosize="{ minRows: 3, maxRows: 8}" style="width: 350px;" @input="$forceUpdate()" placeholder="">
                        </el-input>
                    </el-form-item>
                </div>
            </el-form>
        </div>
        <div style="border-top: 1px solid #F1F1F3;font-size: 13px;padding-top: 25px">
            <div v-for="(item,index) in logList" :key="index">
                {{timeFormat(new Date(item.createTime),"yyyy-MM-dd HH:mm:ss")}}
                操作者[<span style="color: #4cc0c1;">{{item.createUser}}</span>]
                审核操作[<span style="color: #4cc0c1;">{{getStatus(item.status)}}</span>]
                批复[<span style="color: #4cc0c1;">{{item.reply}}</span>]
                备注[<span style="color: #4cc0c1;">{{item.comment}}</span>]
            </div>
        </div>

        <el-dialog :visible.sync="dialogImageVisible" :modal="false">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </r-e-dialog>
</template>

<script>
    import {getLogList,approval} from "@/api/exemption-application";
    import { timeFormat } from "@custom/index";
    import { MessageInfo, MessageError, MessageSuccess} from "@custom/message";
    import {createFullImageUrl} from "@/components/Upload/index.js"
    export default {
        name: "dialog-exemption-application",
        data(){
            return {
                dialogVisible: false,
                applyData: {},
                rules: {},
                logList: [],
                list: [],
                status: "",
                type: "",
                dialogImageUrl:'',
                dialogImageVisible:false
            };
        },
        props: {

        },
        methods:{
            async openDialog(data,type){
                let that=this;
                this.applyData = data;
                let {uuid} = data;
                const loading = this.$loading({lock: true, text: "请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                let logData = await getLogList(uuid);
                loading.close();
                if(logData){
                    let {list} = logData;
                    that.logList = list;
                }
                let {statusCurrent}=data;
                this.status= this.getStatus(statusCurrent);
                this.getExamineList();
                this.type=type;
                this.dialogVisible = true;
            },
            clickSubmit(){
                let that=this;
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        const loading = this.$loading({lock: true, text: "请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                        let {uuid,openId, businessLicensePic, idCardFront, idCardReverse, otherCertificate, enterpriseNature, sublease, applicantName,
                            applicantPhone, actualName, actualPhone, statusCurrent, latestDate, transactionCode, leasorName, leasorPhone, leasorAddress,
                            comment, latestReply, bankDeposit, bankAccount, bankName, way, rentAmount, rentDate, yearAmount, actualAmount, noticeDate}=that.applyData;
                        let data={uuid,openId, businessLicensePic, idCardFront, idCardReverse, otherCertificate, enterpriseNature, sublease, applicantName,
                            applicantPhone, actualName, actualPhone, statusCurrent, latestDate, transactionCode, leasorName, leasorPhone, leasorAddress,
                            comment, latestReply, bankDeposit, bankAccount, bankName, way, rentAmount, rentDate, yearAmount, actualAmount, noticeDate}

                        approval(data).then(res=>{
                            MessageSuccess('审批成功');
                            loading.close();
                            this.clickCancel();
                        }).catch(err=>{
                            MessageError('审批失败');
                            loading.close();
                        });
                    }
                });
            },
            clickCancel(){
                this.$emit('handleSearch',false);
                this.dialogVisible = false;
            },
            timeFormat(date, fmt){
                return timeFormat(date, fmt);
            },
            getStatus(status){
                let x="";
                switch (status)
                {
                    case 0:x="预审资料";
                        break;
                    case 10:x="预审资料";
                        break;
                    case 11:x="重新提交";
                        break;
                    case 12:x="预审不通过";
                        break;
                    case 16:x="预审通过";
                        break;
                    case 20:x="提交纸质资料";
                        break;
                    case 21:x="纸质资料重新提交";
                        break;
                    case 22:x="纸质资料不通过";
                        break;
                    case 26:x="纸质资料通过";
                        break;
                    case 36:x="打印";
                        break;
                    case 40:x="打印完成";
                        break;
                }
                return x;
            },
            getExamineList(){
                let list=[];
                let {statusCurrent}= this.applyData;
                if((statusCurrent+"").startsWith("0")){
                    list=[
                        {value: 0, label: "预审资料"},
                        {value: 11, label: "重新提交"},
                        {value: 12, label: "预审不通过"},
                        {value: 16, label: "预审通过"},
                        {value: 20, label: "提交纸质资料"},
                    ]
                }
                if((statusCurrent+"").startsWith("1")){
                    list=[
                        {value: 10, label: "预审资料"},
                        {value: 11, label: "重新提交"},
                        {value: 12, label: "预审不通过"},
                        {value: 16, label: "预审通过"},
                        {value: 20, label: "提交纸质资料"},
                    ]
                }
                if((statusCurrent+"").startsWith("2")){
                    list=[
                        {value: 20, label: "提交纸质资料"},
                        {value: 21, label: "纸质资料重新提交"},
                        {value: 22, label: "纸质资料不通过"},
                        {value: 26, label: "纸质资料通过"},
                        {value: 36, label: "打印"},
                    ]
                }
                if((statusCurrent+"").startsWith("3")){
                    list=[
                        {value: 36, label: "打印"},
                        {value: 40, label: "打印完成"}
                    ]
                }
                this.list=list;
            },
            handlePictureCardPreview(uuid) {
                this.dialogImageUrl = 'https://zshc.neoyon.com/hicity/security/third/wisdom/files?uuid='+uuid;
                this.dialogImageVisible = true;
            },
        },
    }
</script>

<style scoped>

</style>