import { baseConfig } from "@/utils/config";

/**
 *  @author miaoFei
 *  @param uuid 文件uuid，根据uuid获取图片
 *  @return string
 *  @desc 回显图片，拼成完整url,可以在img标签下使用,完整的图片路径（相对服务器地址）
 *  @createTime：2022-01-16
 */
export function createFullImageUrl(uuid) {
    return `${ baseConfig.baseURL }applet/rent/files?uuid=${ uuid }`;
}

/**
 *  @author：miaoFei
 *  @param：uuidList: uuid[],uuid的数组集合
 *  @param：name 文件名称
 *  @param uuid 文件uuid，根据uuid获取图片
 *  @return：fileList: FileItem[]
 *  @desc：回显的文件，处理成在UploadImage中能够回显的规范的格式
 *  @createTime：2022-01-16
 */
export function createFormatUploadImages(uuidList = []) {
    return uuidList.map(uuid => {
        return {
            name: uuid,
            url: createFullImageUrl(uuid)
        };
    });
}
